<template>
  <v-container fluid>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          REPORTES DE INVENTARIO
        </h3>
      </v-card-title>
      <v-spacer></v-spacer>
    </v-card>
      <v-row >

        <v-col cols="100" sm="30" md="4">
          <v-container >

            <v-card
                max-width="350"
                class="mx-auto"
            >
             <v-list two-line>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-table-edit
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Inventario General</v-list-item-title>
                    <v-list-item-subtitle>Laboratorio - Presentacion - stock por lote</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

               <v-list-item>
                 <v-list-item-content>
                   <v-btn
                       rounded
                       color="primary"
                       dark
                       @click="getReportInventaryGeneral(user[0].area.entity.id)"
                   >
                     DESCARGAR
                   </v-btn>
                 </v-list-item-content>
               </v-list-item>
               <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-chart-histogram
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Inventario Valorizado</v-list-item-title>
                    <v-list-item-subtitle>Utilidad neta</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

               <v-list-item>
                 <v-list-item-content>
                   <v-btn
                       rounded
                       color="primary"
                       dark
                       @click="getReportInventarioValorizado(user[0].area.entity.id)"
                   >
                     DESCARGAR
                   </v-btn>
                 </v-list-item-content>
               </v-list-item>
               <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-playlist-check
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Inventario de Stock Actualizado</v-list-item-title>
                    <v-list-item-subtitle>Para verificar la cantidad</v-list-item-subtitle>

                  </v-list-item-content>
                </v-list-item>

               <v-list-item>
                 <v-list-item-content>
                   <v-btn
                       rounded
                       color="primary"
                       dark
                       @click="getReportInventary(user[0].area.entity.id)"
                   >
                     DESCARGAR
                   </v-btn>
                 </v-list-item-content>
               </v-list-item>
               <v-divider inset></v-divider>
              </v-list>
            </v-card>

          </v-container>
        </v-col>

        <v-col>
          <template>
            <v-card
                class="mx-auto text-center"
                color="blue"
                dark
                max-width="1200"
            >
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                      :value="value"
                      color="rgba(255, 255, 255, .7)"
                      height="120"
                      padding="25"
                      stroke-linecap="round"
                      smooth
                  >
                    <template v-slot:label="item">
                      ${{ item.value }}
                    </template>

                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h4 font-weight-thin">
                  Utilidad de los ultimos 7 meses
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                    block
                    text
                >
                  Ver reporte general
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>


      </v-row>
    </v-container>


</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
import PDF from 'jspdf'
import 'jspdf-autotable';

export default {
  name: "Inventory",
  data () {

    return {
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        750,
      ],
   }
  },

  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {

    getReportInventaryGeneral (id) {
      setTimeout(() => {
        axios.get(`api/report/productStock/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDFInventaryGeneral()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsInventaryGeneral() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        stock: 'STOCK',
        lot: 'LOTE',
        expiration_date: 'VENCIMIENTO',
      }];
    },
    bodyRowsInventaryGeneral(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          stock: pdfBody[i].stock,
          lot: pdfBody[i].lot,
          expiration_date: pdfBody[i].expiration_date,
        });
      }
      return body;
    },
    createPDFInventaryGeneral () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'CONTROL DE INVENTARIO')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsInventaryGeneral(),
        body: this.bodyRowsInventaryGeneral(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 220,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 75, halign: 'left'},
          2: {cellWidth: 80, halign: 'left'},
          3: {cellWidth: 40, valign: 'middle', halign: 'center'},
          4: {cellWidth: 50, valign: 'middle', halign: 'right'},
          5: {cellWidth: 60, halign: 'right'},
        }
      });

      doc.save(`Inventario${Date.now()}.pdf`);
    },








    getReportInventarioValorizado (id) {
      setTimeout(() => {
        axios.get(`api/report/productStockValorizado/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.utilidad=response.data.utilidad
              this.sucursal=response.data.sucursal
              this.createPDF()
            })
            .catch(error => console.log(error))
      }, 1000)
    },


    headRows() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        lot: 'LOTE',
        expiration_date: 'VENCIMIENTO',
        buy_unit:'PRECIO COMPRA',
        sale_unit:'PRECIO VENTA',
        stockFormat: 'STOCK',
        totalCompras: 'TOTAL COMPRAS',
        totalventas: 'TOTAL VENTAS'

      }];
    },
    bodyRows(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          lot: pdfBody[i].lot,
          expiration_date: pdfBody[i].expiration_date,
          buy_unit:pdfBody[i].buy_unit,
          sale_unit:pdfBody[i].sale_unit,
          stockFormat: pdfBody[i].stockFormat,
          totalCompras: pdfBody[i].totalCompras,
          totalventas: pdfBody[i].totalventas,
        });
      }
      return body;
    },
    createPDF () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {
        /*let header_0 = () => {
          doc.setFontSize(6);
          doc.text(39, 30, this.entity)
        };
        doc.autoTable({didDrawPage : header_0});*/

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'INVENTARIO VALORIZADO')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 2.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(10);

          const compras = parseFloat(this.utilidad[0].totalCompras).toFixed(2);
          doc.text(40, 90, `COSTO VALORIZADO DE COMPRAS:  ${compras}`)

        };
        doc.autoTable({didDrawPage : header_6});
        let header_7 = () => {
          doc.setFontSize(10);

          const ventas = parseFloat(this.utilidad[0].totalVentas).toFixed(2);
          doc.text(40, 105, `COSTO VALORIZADO DE VENTAS:  ${ventas}`)

        };
        doc.autoTable({didDrawPage : header_7});
        let header_8 = () => {
          doc.setFontSize(10);

          const utilidad = parseFloat(this.utilidad[0].utilidad).toFixed(2);
          doc.text(40, 120, `UTILIDAD NETA VALORIZADA:  ${utilidad}`)

        };
        doc.autoTable({didDrawPage : header_8});

        let header_9 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_9});

      };
      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 130},
        styles: {
          cellPadding: 1,
          fontSize: 5,
          //fontStyle: 'bold',
          valign: 'middle',
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRows(),
        body: this.bodyRows(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 6,


        },
        columnStyles: {
          0: {cellWidth: 180,fontSize: 6, halign: 'left'},
          1: {cellWidth: 60,fontSize: 5, halign: 'left'},
          2: {cellWidth: 60,fontSize: 5, halign: 'left'},
          3: {cellWidth: 40, valign: 'middle', halign: 'center'},
          4: {cellWidth: 40, valign: 'middle', halign: 'right'},
          5: {cellWidth: 40, valign: 'middle', halign: 'right'},
          6: {cellWidth: 30, valign: 'middle', halign: 'right'},
          7: {cellWidth: 30, valign: 'middle', halign: 'center'},
          8: {cellWidth: 30, valign: 'middle', halign: 'right'},
          9: {cellWidth: 30, valign: 'middle', halign: 'right'},
        }
      });

      doc.save(`Inventario${Date.now()}.pdf`);
    },




    getReportInventary (id) {
      setTimeout(() => {
        axios.get(`api/report/productStockInventary/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              this.createPDFInventary()
            })
            .catch(error => console.log(error))
      }, 1000)
    },


    headRowsInventary() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        cantidad: 'STOCK',


      }];
    },
    bodyRowsInventary(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          cantidad: pdfBody[i].cantidad,

        });
      }
      return body;
    },
    createPDFInventary () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {
        /*let header_0 = () => {
          doc.setFontSize(6);
          doc.text(39, 30, this.entity)
        };
        doc.autoTable({didDrawPage : header_0});*/

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'INVENTARIO STOCK GENERAL')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 2.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});



        let header_9 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_9});

      };
      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 130},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          //fontStyle: 'bold',
          valign: 'middle',
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsInventary(),
        body: this.bodyRowsInventary(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 10,


        },
        columnStyles: {
          0: {cellWidth: 270,fontSize: 10, halign: 'left'},
          1: {cellWidth: 120,fontSize: 10, halign: 'left'},
          2: {cellWidth: 120,fontSize: 10, halign: 'left'},
          3: {cellWidth: 30, fontSize: 10, valign: 'middle', halign: 'center'},

        }
      });

      doc.save(`InventarioStockActual${Date.now()}.pdf`);
    },






  }
}
</script>
